@use "sass:map";
@use "@angular/material" as mat;
@import "variables";

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark-theme: map.get($theme, is-dark);

  gt-payment {
    .plans {
      .mat-mdc-card {
        @media (min-width: $large) {
          border-right: 1px solid if(
            $is-dark-theme,
            mat.get-color-from-palette(mat.$gray-palette, 800),
            mat.get-color-from-palette(mat.$gray-palette, 300)
          );
        }
      }
    }
  }
}
