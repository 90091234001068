@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $is-dark-theme: map.get($theme, is-dark);

  gt-project-filter-bar {
    .mat-expansion-panel-header.mat-expansion-panel-header[aria-disabled="true"].disabled-looking {
      color: if(
        $is-dark-theme,
        mat.get-color-from-palette(mat.$gray-palette, 700),
        mat.get-color-from-palette(mat.$gray-palette, 400)
      );
    }
  }
}
