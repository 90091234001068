$candy-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $candy-app-primary,
      accent: $candy-app-accent,
      warn: $candy-app-warn,
    ),
  )
);

$candy-app-theme-dark: mat.define-dark-theme(
  (
    color: (
      primary: $candy-app-primary-contrast,
      accent: $candy-app-accent,
      warn: $candy-app-warn-contrast,
    ),
  )
);

@mixin theme-extension($theme) {
  $_background: map-get($theme, background);
  $app-bg-color: map-get($_background, background);
  $_foreground: map-get($theme, foreground);
  $app-fg-color: map-get($_foreground, text);

  body {
    background-color: $app-bg-color;
    color: $app-fg-color;
  }
}

@function modify-background($theme, $background-color, $foreground-color) {
  $bg2: mix($background-color, white, 95%);

  // Replace the background and foreground in the color object's palette.
  $theme-color: map-get($theme, color);
  $color-background-palette: map-get($theme-color, background);
  $color-background-palette: map-merge(
    $color-background-palette,
    (
      background: $background-color,
    )
  );
  $color-background-palette: map-merge(
    $color-background-palette,
    (
      raised-button: $background-color,
    )
  );
  $color-background-palette: map-merge(
    $color-background-palette,
    (
      dialog: $bg2,
      card: $bg2,
      selected-button: $background-color,
    )
  );
  $color-foreground-palette: map-get($theme-color, foreground);
  $color-foreground-palette: map-merge(
    $color-foreground-palette,
    (
      foreground: $foreground-color,
    )
  );
  $color-foreground-palette: map-merge(
    $color-foreground-palette,
    (
      base: $foreground-color,
      icon: $foreground-color,
      icons: $foreground-color,
      text: $foreground-color,
      slider-min: $foreground-color,
    )
  );

  // Replace the background in the background palette (for legacy reasons)
  $background-palette: map-get($theme, background);
  $background-palette: map-merge(
    $background-palette,
    (
      background: $background-color,
    )
  );
  $background-palette: map-merge(
    $background-palette,
    (
      raised-button: $background-color,
    )
  );
  // Replace the foreground in the foreground palette
  $foreground-palette: map-get($theme, foreground);
  $foreground-palette: map-merge(
    $foreground-palette,
    (
      foreground: $foreground-color,
    )
  );

  // Merge the changes into a new theme.
  $modified-theme-color: map-merge(
    $theme-color,
    (
      background: $color-background-palette,
      foreground: $color-foreground-palette,
    )
  );
  $modified-theme: map-merge(
    $theme,
    (
      color: $modified-theme-color,
    )
  );
  $modified-theme: map-merge(
    $modified-theme,
    (
      background: $background-palette,
      foreground: $foreground-palette,
    )
  );

  @return $modified-theme;
}

$candy-app-theme-dark: modify-background($candy-app-theme-dark, #181818, #FAFAFA);
